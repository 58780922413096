import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { capitalize } from '@open-tender/js'
import { OpenTenderAPI } from '@open-tender/redux'
import { isBrowser } from 'react-device-detect'
import getConfig from 'next/config'

const { publicRuntimeConfig } = getConfig()
const {
  OPENTENDER_AUTH_URL,
  OPENTENDER_API_URL,
  OPENTENDER_CLIENT_ID,
  OPENTENDER_BRAND_ID,
} = publicRuntimeConfig

const baseUrl = OPENTENDER_API_URL
const authUrl = OPENTENDER_AUTH_URL
const clientId = OPENTENDER_CLIENT_ID
const brandId = OPENTENDER_BRAND_ID

const initialState = {
  app: null,
  brand: null,
  content: null,
  theme: null,
  settings: null,
  api: null,
  loading: 'idle',
  error: null,
  retries: 0,
}

// fetch config via explicit brandId and clientId
export const fetchConfig = createAsyncThunk(
  'config/getConfig',
  async (_, thunkAPI) => {
    try {
      const options = {
        baseUrl, authUrl, clientId, brandId,
      }
      const api = new OpenTenderAPI(options)
      const response = await api.getConfig()
      const app = {
        baseUrl, authUrl, clientId, brandId,
      }
      return { ...response, app }
    } catch (err) {
      return thunkAPI.rejectWithValue(err)
    }
  },
)

const configSlice = createSlice({
  name: 'config',
  initialState,
  reducers: {
    resetConfig: () => initialState,
    resetRetries: (state) => {
      state.retries = 0
    },
    incrementRetries: (state) => {
      state.retries += 1
    },
  },
  extraReducers: {
    [fetchConfig.fulfilled]: (state, action) => {
      const {
        app, brand, content, theme, settings,
      } = action.payload
      state.app = app
      state.brand = brand
      state.content = content
      state.theme = theme
      state.settings = settings
      state.loading = 'idle'
      state.api = new OpenTenderAPI(app)
      state.error = null
      state.retries = 0
    },
    [fetchConfig.pending]: (state) => {
      state.loading = 'pending'
    },
    [fetchConfig.rejected]: (state, action) => {
      state.error = action.payload
      state.loading = 'idle'
    },
  },
})

export const { resetConfig, resetRetries, incrementRetries } = configSlice.actions

export const selectBrand = (state) => state.config.brand
export const selectTheme = (state) => state.config.theme
export const selectConfig = (state) => state.config.content
export const selectSettings = (state) => state.config.settings
export const selectAPI = (state) => state.config.api

export const selectAccountConfig = (state) => state.config.content.account
export const selectOutpostName = (state) => capitalize(state.config.settings.locationName.OUTPOST[0])
export const selectDisplaySettings = (state) => {
  const orderType = state.data.order.orderType || 'OLO'
  return state.config.settings.displaySettings[orderType]
}
export const selectConfigRetries = (state) => state.config.retries
export const selectLightColor = (state) => (state.theme ? state.theme.colors.light : '#ffffff')
export const selectOptIns = (state) => {
  const { accepts_marketing, order_notifications } = state.config.brand
  return { accepts_marketing, order_notifications }
}
export const selectFulfillment = (state) => state.config.brand.fulfillment
export const selectRecaptcha = (state) => state.config.settings.recaptcha

export const selectHeaderHeight = (state) => {
  if (!state.config.theme) return 0
  const { navHeight, navHeightMobile } = state.config.theme.layout
  const height = isBrowser ? navHeight : navHeightMobile
  return parseInt(height.replace('rem', '')) * 10
}

export default configSlice.reducer

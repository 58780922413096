/* eslint-disable prefer-destructuring */
const breakpoints = ['600px', '960px', '1024px', '1440px'];
breakpoints.sm = breakpoints[0];
breakpoints.md = breakpoints[1];
breakpoints.lg = breakpoints[2];
breakpoints.xl = breakpoints[3];

/* eslint-enable prefer-destructuring */
const theme = {
  breakpoints,
  fonts: {
    default: 'roc-grotesk',
    RocGrotesk: 'roc-grotesk',
    RocGroteskCompressed: 'roc-grotesk-compressed',
    RocGroteskCondensed: 'roc-grotesk-condensed',
    RocGroteskExtraWide: 'roc-grotesk-extrawide',
    RocGroteskWide: 'roc-grotesk-wide',
    OpenSans: 'Open Sans, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
  },
  // https://chir.ag/projects/name-that-color
  colors: {
    alto: '#E0E0E0',
    selectiveYellow: '#ffb700',
    cornflowerBlue: '#4086EF',
    rioGrande: '#C9DE00',
    denim: '#135ECC',
    tundora: '#4D4D4D',
    havelockblue: '#5C88DA',
    larioja: '#BABC16',
  },
  grid: {
    gutters: {
      sm: '10px',
      md: '10px',
      lg: '30px',
      xl: '30px',
    },
    margin: {
      sm: '25px',
      md: '25px',
      lg: '106px',
      xl: '106px',
    },
  },
  zIndices: {
    bg: 1,
    content: 10,
    front: 100,
    nav: 500,
    overlayBg: 1000,
    overlay: 1001,
  },
};

export default theme;

import '../styles/global.css'

import React from 'react'
import {
  oneOfType,
  func,
  object,
  shape,
} from 'prop-types'
import { useRouter } from 'next/router'
import { ContentfulCache, ContentfulProvider } from 'react-contentful'
import { initContentful } from 'next-contentful'
import { Provider } from 'react-redux'
import initStore from 'store/initStore'
import { ThemeProvider } from 'styled-components'
import theme from 'styles/theme'
import withStore from 'store/withStore'

const App = (appCtx) => {
  const { Component, pageProps, store } = appCtx
  const router = useRouter()

  const contentfulClient = initContentful({
    space: process.env.NEXT_PUBLIC_CONTENTFUL_SPACE_ID,
    accessToken: router?.query?.preview
      ? process.env.NEXT_PUBLIC_CONTENTFUL_TOKEN_PREVIEW
      : process.env.NEXT_PUBLIC_CONTENTFUL_TOKEN_DELIVERY,
    host: router?.query?.preview
      ? 'preview.contentful.com'
      : 'cdn.contentful.com',
    cache: pageProps?.contentfulCache && pageProps.cache instanceof ContentfulCache
      ? pageProps.contentfulCache
      : (new ContentfulCache().restore(pageProps?.contentfulCache)),
  })

  return (
    <ContentfulProvider client={contentfulClient}>
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <Component {...pageProps} />
        </ThemeProvider>
      </Provider>
    </ContentfulProvider>
  )
}

App.propTypes = {
  Component: oneOfType([func, object]).isRequired,
  pageProps: shape({}),
}

App.defaultProps = {
  pageProps: {},
}

App.getInitialProps = async ({ Component, ctx }) => {
  let pageProps = {}

  if (Component.getInitialProps) {
    pageProps = await Component.getInitialProps(ctx)
  }

  return {
    pageProps,
  }
}

export default withStore(initStore)(App)

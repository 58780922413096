import { ADD_ITEMS, ADD_ITEM, SET_LOCATION } from './actions'

const initialState = {
  entries: {},
  locationId: null,
}

const itemsReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_ITEMS:
      return {
        ...state,
        entries: {
          ...state.entries,
          ...action.items.reduce((acc, cur) => { acc[cur.slug] = cur; return acc; }, {}),
        },
      }
    case ADD_ITEM:
      return {
        ...state,
        entries: {
          ...state.entries,
          [action.item.slug]: action.item,
        },
      }
    case SET_LOCATION:
      return {
        ...state,
        locationId: action.locationId,
      }
    default:
      return {
        ...state,
      }
  }
}

export default itemsReducer

import { SET_FILTERS } from './actions'

const initialState = {
  filters: [],
}

const allergenReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_FILTERS:
      return {
        ...state,
        filters: action.filters,
      }
    default:
      return {
        ...state,
      }
  }
}

export default allergenReducer

export const ADD_ITEMS = 'items/ADD_ITEMS'
export const ADD_ITEM = 'items/ADD_ITEM'
export const SET_LOCATION = 'items/SET_LOCATION'

export const addItems = (items) => ({
  type: ADD_ITEMS,
  items,
})

export const addItem = (item) => ({
  type: ADD_ITEM,
  item,
})

export const setLocation = (locationId) => ({
  type: SET_LOCATION,
  locationId,
})

import {
  configureStore,
  combineReducers,
  getDefaultMiddleware,
} from '@reduxjs/toolkit'
import { openTenderReducer } from '@open-tender/redux'
import configReducer from './slices/configSlice'
import allergens from './allergens/reducer'
import items from './items/reducer'

const rootReducer = combineReducers({
  config: configReducer,
  data: openTenderReducer,
  allergens,
  items,
})

const customizedMiddleware = getDefaultMiddleware({
  immutableCheck: false,
  serializableCheck: false,
})

const initStore = (preloadedState) => configureStore({
  reducer: rootReducer,
  preloadedState,
  middleware: customizedMiddleware,
})

export default initStore
